$primary-color: #762fb7;

.title {
  font-size: 0.8em;
}
.borderBottom {
  width: 100px;
  position: relative;
  margin-top: auto;
  border-bottom: 5px solid $primary-color;
  margin-bottom: 5px;
}

.prices {
  .discountPrice {
    color: $primary-color;
    font-weight: bolder;
  }
  .normalPrice {
    text-decoration: line-through;
    font-size: 0.8em;
    font-weight: bold;
  }
}
